<template>
  <transition-group tag="ul" name="list" class="inquiry-order-ul">
    <li v-for="item in order.data" :key="item.id">
      <router-link
          :to="{
          name: 'OrderDetail',
          query: { id: item.id, num: item.orderNum },
        }"
      >
        <div class="inquiry-order-content">
          <div class="inquiry-order-date" v-if="item.createTime">
            <span>{{dateFormat(item.createTime).substr(8, 2)}}</span>
            <hr style="border:0;height:1px;background-color: #dcdcdc;">
            <p>{{dateFormat(item.createTime).substr(0, 4)}}/{{dateFormat(item.createTime).substr(5, 2)}}</p>
          </div>

          <div class="inquiry-right-content">
            <div class="inquiry-order-no">
              单号：<span>{{ item.orderNum }}</span>
              <!-- <span class="inquiry-price">￥{{item.totalPrice}}</span> -->
              <template v-if="biaowuPriceOpen && biaowuPriceOpen == '1'">
                <!-- <span class="inquiry-price">{{Number(item.totalPrice+item.farePrice).toFixed(2) || ''}}</span> -->
                <span class="inquiry-price">{{Number(item.totalPrice).toFixed(2) || ''}}</span>

              </template>
            </div>

            <template v-if="item.adminNotes">
              <div>
                跟单人：
                
                <span>{{ item.adminNotes?item.adminNotes : "无" }}</span>
              </div>
              <div>
                接单时间：<span>{{dateFormat(item.lastModifyTime).substr(0, 10)}}</span>
              </div>
            </template>

            <div v-else class="inquiry-wait">状态：<span>待接单</span></div>
          </div>
        </div>
      </router-link>
    </li>
  </transition-group>
  <transition-group tag="ul" v-if="order.loading" name="list">
    <li class="class-li skeleton" v-for="item in 10" :key="item">
      <div class="class-picture-wapper skeleton-bg"></div>

      <div class="class-info-wapper">
        <div class="class-title skeleton-bg"></div>

        <div class="class-author skeleton-bg"></div>

        <div class="class-time skeleton-bg"></div>
      </div>
    </li>
  </transition-group>
</template>



<script lang="ts">
import { defineComponent, onMounted, inject, watch, ref } from "vue";
import dateFormat from "../../utils/dateFormat";
import axios from "axios";
export default defineComponent({
  name: "ClassItem",
  props: {
    order: Object
  },
  
  setup(props) {
    const userInfo = inject("userInfo") as any;
    const biaowuPriceOpen = ref()

    watch(
      () => {
        return props.order
      },
      (NewsVue) => {
        biaowuPriceOpen.value = (NewsVue as any).totalResult && (NewsVue as any).totalResult[0] && (NewsVue as any).totalResult[0].str
      }
    )

    return {
      dateFormat,
      userInfo,
      biaowuPriceOpen
    };
  }
});
</script>


<style lang="scss" scoped>
.class-li {
  display: block;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 10px 0;
  padding-left: 10px;
  position: relative;
  border-bottom: 1px solid #eee;
  a {
    overflow: hidden;
  }
}
.class-picture-wapper {
  width: 110px;
  height: 90px;
  float: left;
  position: relative;
}

.class-info-wapper {
  width: auto;
  height: 100%;
  padding-left: 130px;
}

.class-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #444444;
  font-size: 16px;
  /* // font-weight: bold; */
  height: 40px;
  line-height: 20px;
}

.class-author {
  margin-top: 7px;
  color: #999999;
  font-size: 14px;
  a {
    color: #B9135A;
    font-size: 14px;
  }
}

.class-time {
  margin-top: 10px;
  color: #999999;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 14px;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s ease-in-out;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  to {
    background-position: 0 50%;
  }
}

.skeleton {
  .class-info-wapper {
    padding-right: 10px;
  }
  .class-title {
    height: 15px;
  }
  .class-author {
    height: 10px;
    margin-top: 32px;
    width: 120px;
    margin-bottom: 20px;
  }
  .class-time {
    height: 10px;
    width: 80px;
  }

  .skeleton-bg {
    background: linear-gradient(
      90deg,
      hsla(0, 0%, 74.5%, 0.2) 25%,
      hsla(0, 0%, 50.6%, 0.24) 37%,
      hsla(0, 0%, 74.5%, 0.2) 63%
    );
    background-size: 400% 100%;
    animation: skeleton-loading 1.4s ease infinite;
  }
}

// 待确认
.inquiry-order-ul li {
  font-size: 14px;
  box-sizing: border-box;
  padding: 15px;
  overflow: hidden;
  border-bottom: 5.6px solid #f4f4f4;
  position: relative;
  color: #999;
}

.inquiry-order-content{
  height:75px;
}

.inquiry-order-date {
  width: 60px;
  height: 75px;
  padding-top: 9px;
  display: inline-block;
  float: left;
  color: #999;
  margin-right: 12px;
  text-align: center;
}

.inquiry-order-date span {
  display: inline-block;
  font-size: 0.5rem;
  color: #999999;
  text-align: center;
  height: 0.6rem;
  line-height: 0.5rem;
  font-weight: bold;
}

.inquiry-order-date p {
  text-align: center;
  color: #999999;
  line-height: 0.5rem;
}

.inquiry-right-content {
  color: #999;
}

.inquiry-order-no {
  color: #333;
}

.inquiry-right-content>div {
  height: 25px;
  line-height: 25px;
}

.inquiry-price {
  color: #b9135a;
  float: right;
}

.inquiry-wait {
  height: 50px!important;
  line-height: 50px!important;
}

.inquiry-wait span {
  color: #b9135a;
}

</style>
